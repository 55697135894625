$main: #2F3031;
$mainLight: #f0f2f5;
$mainWhite: #fff;
$borderColor: #dacbcb;
$borderDark: #000;
$buttonBorder: $mainLight;
$tabButtonBorder: $main;

.ant-layout-sider {
  background: $mainWhite;
}

::selection {
  color: #fff;
  background: $borderDark;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: $buttonBorder;
}

.ant-btn-group .ant-btn-primary+.ant-btn-primary, .ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-left-color: $buttonBorder;
}

.ant-btn-primary, .ant-btn-primary:focus {
  background-color: $main;
  border-color: $buttonBorder;
}

.ant-statistic-content {
  white-space: nowrap;
}

.ant-btn-primary:hover {
  color: #fff;
  background-color: lighten($borderDark, 10%);
  background: lighten($borderDark, 10%);
  border-color: $buttonBorder;
  box-shadow: none;
}

.ant-layout-header {
  display: block;
  border-bottom: 1px solid $borderColor;
  @media (min-width: 1001px) {
    display: none;
  }
}

.ant-btn,
.ant-drawer-close,
.ant-btn:hover,
.ant-btn:focus {
  box-shadow: none;
}

form .ant-btn-primary {
  box-shadow: none;
  background-color: $main!important;
  border: 3px solid $main;
}
form .ant-btn-primary:hover,
form .ant-btn-primary:focus {
  color: #000;
  background-color: #fff!important;
  border: 3px solid $main;
}

.ant-btn-primary[type="submit"] {
  padding: 0 15px;
}

.ant-select-dropdown-menu {
  margin: 0;
}

.ant-input[type="text"] {
  padding: 4px 11px;
}

.ant-tabs-nav-wrap,
.ant-tabs-nav-scroll {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 0;
  padding-left: 0;
}

.ant-tabs-nav-scroll {
  white-space: normal;

}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: auto;
  white-space: normal;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin: 5px;
}

.ant-layout.ant-layout-has-sider>.ant-layout {
  overflow-x: initial;
  background: $mainWhite;
}

.ant-drawer-close {
  @media (max-width: 1000px) {
    background: $main;
    border: 2px solid $main;
    height: 64px;
    width: 64px;
    border-bottom-left-radius: 8px;
    color: $mainWhite;
  }
}

.ant-drawer-close:hover {
  @media (max-width: 1000px) {
    color: $main;
    background: $mainLight;
  }
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-spin-dot i, .ant-tabs-ink-bar {
    background-color: $main;
    border-color: $main;
}

.ant-input:focus,
.ant-select-selection:focus {
  outline: 0;
  box-shadow: none;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-input:focus, .ant-input:hover,
.ant-select-selection:hover,
.ant-select-selection:focus {
    border-color:  $main;
}

a:hover,
a,
a:focus,
a:visited,
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: $main;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border-radius: 7px;
  border: 1px solid $tabButtonBorder;
  border-bottom: 1px solid $tabButtonBorder;
  color: $tabButtonBorder;
  background: #fff;
}

.ant-btn:hover {
  color: $mainWhite;
  border: 1px solid $tabButtonBorder;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: $main;
  color: #fff;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  margin-bottom: 10px;
}

@media (max-width: 1000px) {
  .ant-drawer-body .ant-col-12 {
    width: 100%;
  }
  .ant-drawer-body {
    height: 100%;
    .ant-layout-sider {
      height: 100%;
    }
  }
}

.custom-chevron > svg{
  fill: #000!important;
}

.ant-drawer-content-wrapper {
  max-width: 100%;
}

.ant-statistic-title {
  font-size: 13px;
}
.ant-select-dropdown.to-up {
  animation: none!important;
  animation-name: none!important;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft.to-up,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft.to-up {
  animation: none!important;
  animation-name: none!important;
}

.to-up .ant-select-dropdown-menu {
  max-height: 380px;
}

#__calculator_root__ {
  position: relative;
  z-index: 1;
}

.ant-slider:hover .ant-slider-track, .ant-slider-track {
  background-color: transparent;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open), .ant-slider-handle:focus, .ant-slider-handle:hover {
  border-color: $main;
}

.ant-slider-handle {
  border: 2px solid $main;
}

.ant-slider-rail, .ant-slider-rail:hover, .ant-slider:hover .ant-slider-rail {
  background-color: $main;
}

.ant-slider-handle:focus, .ant-slider-handle:hover {
  box-shadow: none;
}
